import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function TicketList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "ticket_id", sortable: true, label: "Ticket ID" },
        { key: "user", sortable: true, label: "User" },
        {
            key: "support_category.name",
            sortable: false,
            label: "Support Category"
        },
        { key: "subject", sortable: false, label: "Subject" },
        { key: "created_at", sortable: true, label: "Created At" },
        { key: "updated_at", sortable: true, label: "Updated At" },
        { key: "status", sortable: true, label: "Status" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalTicketList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const ticketsMetaData = ref([]);
    const ticketStatusOption = ref([]);
    const categoryFilter = ref(null);
    const ticketCategoriesOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalTicketList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, statusFilter, categoryFilter],
        () => {
            refetchData();
        }
    );

    const fetchTicketList = (ctx, callback) => {
        store
            .dispatch("app-tickets/fetchTickets", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                category: categoryFilter.value
            })
            .then(response => {
                const ticket = response.data.data;
                const { total } = response.data.meta;
                ticketStatusOption.value = response.data.status;
                ticketCategoriesOption.value = response.data.categories;

                callback(ticket);
                totalTicketList.value = total;
                ticketsMetaData.value = ticket;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching tickets",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error fetching tickets`,
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                        },
                        {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                        })
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == "pending") return "primary";
        if (status == "resolved") return "info";
        if (status == "closed") return "success";
        return "danger";
    };

    return {
        fetchTicketList,
        tableColumns,
        perPage,
        currentPage,
        totalTicketList,
        ticketsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        ticketStatusOption,
        statusFilter,
        categoryFilter,
        ticketCategoriesOption
    };
}
