<template>
  <b-sidebar
    id="add-sidebar"
    ref="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:sidebarActive', val)"
    width="40%"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="floating-closeright d-flex align-items-center justify-content-center p-25" @click="confirmCloseButton">
        <feather-icon icon="XIcon" size="26" stroke="#101828"/>
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom"
      >
        <h3 v-if="!data_local.hashid" class="mb-0 font-weight-bold">
          New Ticket
        </h3>
        <h3 v-else class="mb-0 font-weight-bold">Edit Ticket  - <span class="text-danger text-uppercase">{{ data_local.ticket_id }}</span></h3>
        <div>
          <!-- <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
          >
            Cancel
          </b-button> -->
          <b-button
            v-if="!data_local.hashid"
            variant="primary"
            @click="formSubmitted()"
            class="px-3"
          >
            Submit
          </b-button>

          <b-button v-else variant="primary" @click="formUpdate()" class="px-3">
            Update
          </b-button>
        </div>
      </div>
      
      <!-- BODY -->
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="ticket-form" class="p-2">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="ticket-category">
                  Please choose your category
                </label>
                <v-select
                  v-model="data_local.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="support_categories"
                  :clearable="false"
                  :searchable="false"
                  :reduce="(val) => val.code"
                  label="value"
                  input-id="ticket-category"
                  name="category_id"
                  placeholder="Select Ticket Category"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="priority"> Priority </label>
                <v-select
                  v-model="data_local.priority"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="priority_option"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="priority"
                  name="priority"
                  placeholder="Select Priority"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="data_local.hashid && $can('Zircly Admin')">
              <b-form-group>
                <label class="required-l" for="status"> Status </label>
                <v-select
                  v-model="data_local.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="status_option"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="status"
                  name="status"
                  placeholder="Select Status"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Subject"
                  rules="required"
                >
                  <label class="required-l" for="subject">Subject</label>
                  <b-form-input
                    id="subject"
                    v-model="data_local.subject"
                    name="subject"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Subject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="description">
                  Description
                </label>
                <b-form-textarea
                  id="description"
                  v-model="data_local.description"
                  name="description"
                  placeholder="Description"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="">
              <b-form-group label="Attachments" label-for="files">
                <file-pond
                  ref="pond"
                  name="files[]"
                  max-file-size="5MB"
                  max-files="10"
                  allow-multiple="true"
                  v-bind:files="myFiles"
                  image-preview-height="120px"
                  accepted-file-types="image/*,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  file-validate-type-label-expected-types="Expects a PDF, DOC or DOCX file"
                />
                <small class="text-warning flex items-center">
                  File size should be less than 5MB and allowed formats are
                </small>
                <p>( .doc, .docx, .pdf, image)</p>
              </b-form-group>
              <div
                class="border-top pt-1"
                v-if="
                  data_local.attachments && data_local.attachments.length > 0
                "
              >
                <b class="text-secondary">Uploaded Attachments</b>

                <div class="d-flex flex-column">
                  <div
                    v-for="(file, index) in data_local.attachments"
                    :key="index"
                    class="d-flex align-items-center justify-content-between my-1"
                  >
                    <img
                      v-if="file.type.includes('image')"
                      :src="file.link"
                      class="img-fluid"
                      width="100px"
                      height="100px"
                      alt="Attachment"
                    />
                    <span
                      v-else
                      class="text-primary"
                      v-b-tooltip.hover.v-default
                      :title="file.name"
                    >
                      {{ file.name | str_limit(40) }}
                    </span>
                    <div>
                      <feather-icon
                        v-b-tooltip.hover.top="'View Attachment'"
                        size="20"
                        class="btn-link cursor-pointer ml-1"
                        icon="EyeIcon"
                        @click.stop.prevent="openWindow(file.link)"
                      />

                      <feather-icon
                        v-b-tooltip.hover.top="'Remove Attachment'"
                        size="20"
                        class="btn-link cursor-pointer ml-1 text-danger"
                        icon="TrashIcon"
                        @click.stop.prevent="confirmDeleteRecord(file.uuid)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>

            <b-form-group
              v-for="(item, index) in customFields"
              :key="item.id"
              class="col-md-6"
            >
              <!-- #default="{ errors }" -->
              <validation-provider
                :name="item.name"
                rules=""
                #default="validationContext"
              >
                <b-form-group v-if="item.type == 'number'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'text'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="text"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'url'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="url"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'rating'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <div class="d-flex align-items-center">
                    <star-rating
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :show-rating="false"
                      :star-size="30"
                    ></star-rating>
                    <span
                      v-if="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :class="`rating-btn btn ${
                        ratingdescription[
                          data_local.get_customfields_data['field_' + item.id]
                        ].class
                      }`"
                    >
                      {{
                        ratingdescription[
                          data_local.get_customfields_data["field_" + item.id]
                        ].text
                      }}</span
                    >
                    <input
                      type="hidden"
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                    />
                  </div>
                </b-form-group>

                <b-form-group v-if="item.type == 'password'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="password"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'textarea'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-textarea
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    trim
                    :placeholder="item.label"
                  />
                </b-form-group>
                <b-form-group v-if="item.type == 'date'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <flat-pickr
                    :config="{ dateFormat: 'Y-m-d' }"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    class="form-control"
                    placeholder="Select Date.."
                  />
                </b-form-group>

                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarActive",
    event: "update:sidebarActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      data_local: {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
        files: [],
      },
      defaultForm: {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
        files: [],
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
    };
  },
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {
    hasChanged() {
      return Object.keys(this.data_local).some((field) => {
        if (Array.isArray(this.data_local[field])) {
          return (
            JSON.stringify(this.data_local[field]) !=
            JSON.stringify(this.defaultForm[field])
          );
        } else {
          return this.data_local[field] != this.defaultForm[field];
        }
      });
    },
  },
  watch: {
    sidebarActive(val) {
      this.data_local = {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
        files: [],
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
  },
  created() {},
  mounted() {
    this.getCustomFields();
  },
  methods: {
    confirmDeleteRecord(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeAttachment(uuid);
        }
      });
    },
    confirmCloseButton() {
      if (this.hasChanged) {
        this.$swal({
          title: "Are you sure?",
          text: "You are about to exit this form , if you proceed the form data will be lost.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.resetFormData();
            this.$refs.sidebar.hide();
          }
        });
      }
      else
      {
        this.resetFormData();
        this.$refs.sidebar.hide();
      }
    },
    resetFormData() {
      console.log("reset");
      this.data_local = {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
        files: [],
      };
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
    openWindow(link) {
      window.open(link);
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "tickets/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          this.fetchItem(this.data_local.hashid);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deleted",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: "Attachment Deleted Successfully.",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Attachment Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Attachment Deletion failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("ticket-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      if (self.data_local.category_id) {
        formData.append("category_id", self.data_local.category_id);
      }

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      this.isBusy = true;
      this.$store
        .dispatch("app-tickets/addTicket", formData)
        .then((res) => {
          // Add this ticket to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Ticket Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Ticket Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Ticket Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("ticket-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "tickets/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this ticket to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Ticket Updated",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Ticket Updating Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Ticket Updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-tickets/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
          this.data_local.category_id = res.data.data.support_category.hashid;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-tickets/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.ticketStatusOption = res.data.meta.status;
          self.support_categories = res.data.support_categories;
          self.priority_option = res.data.priority_select;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
                  
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
