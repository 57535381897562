<template>
  <b-sidebar
    id="ticketViewSidebar"
    ref="ticketViewSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg support-ticket"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:sidebarActive', val)"
    width="60%"
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom"
      >
        <h3 class="mb-0 font-weight-bold">View Ticket  - <span class="text-danger text-uppercase">{{ data_local.ticket_id }}</span></h3>
        <div>
          <!-- <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
          >
            Close
          </b-button> -->
        </div>
      </div>
      <div class="p-2">
        <!-- BODY -->
        <b-form autocomplete="off" id="ticket-form">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label class="" for="ticket-category"> Ticket category </label>
                <p>
                  {{
                    data_local.support_category
                      ? data_local.support_category.name
                      : ""
                  }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="data_local.user">
              <b-form-group>
                <label class="" for="ticket-priority"> Created By </label>
                <p class="text-capitalize">
                  <b-link
                    v-if="$can('employee_show')"
                    :to="{
                      name: 'view-employee',
                      params: { id: data_local.user.hashid },
                    }"
                    class="font-weight-bold d-block text-nowrap text-dark"
                  >
                    <b-media v-if="data_local.user.hashid">
                      <template #aside>
                        <b-avatar :src="data_local.user.avatar" />
                      </template>
                      <h6 class="mb-0">
                        {{ data_local.user.name }}
                      </h6>
                      <p class="mb-0">
                        {{ data_local.user.designation }}
                      </p>
                    </b-media>
                  </b-link>
                  <b-media v-else>
                    <template #aside>
                      <b-avatar :src="data_local.user.avatar" />
                    </template>
                    <h6 class="mb-0">{{ data_local.user.name }}</h6>
                    <p class="mb-0">
                      {{ data_local.user.designation }}
                    </p>
                  </b-media>
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="" for="ticket-priority"> Ticket priority </label>
                <p class="text-capitalize">
                  {{ data_local.priority }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="" for="ticket-priority"> Ticket Created At </label>
                <p class="">
                  {{ data_local.created_at | formatDateTime }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="" for="ticket-priority"> Last Updated At </label>
                <p class="">
                  {{ data_local.updated_at | formatDateTime }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="" for="ticket-status"> Ticket status </label>
                <p class="text-capitalize">
                  {{ data_local.status }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="" for="subject"> Subject </label>
                <p class="text-capitalize">
                  {{ data_local.subject }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="" for="description"> Description </label>
                <p class="text-capitalize">
                  {{ data_local.description }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div
                class="border-top pt-1"
                v-if="data_local.attachments && data_local.attachments.length > 0"
              >
                <b class="text-secondary">Uploaded Attachments</b>

                <div class="d-flex flex-column">
                  <div
                    v-for="(file, index) in data_local.attachments"
                    :key="index"
                    class="d-flex align-items-center justify-content-between my-1"
                  >
                    <img
                      v-if="file.type.includes('image')"
                      :src="file.link"
                      class="img-fluid"
                      width="100px"
                      height="100px"
                      alt="Attachment"
                    />
                    <span
                      v-else
                      class="text-primary"
                      v-b-tooltip.hover.v-default
                      :title="file.name"
                    >
                      {{ file.name | str_limit(40) }}
                    </span>
                    <div>
                      <feather-icon
                        v-b-tooltip.hover.top="'View Attachment'"
                        size="20"
                        class="btn-link cursor-pointer ml-1"
                        icon="EyeIcon"
                        @click.stop.prevent="openWindow(file.link)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>

            <b-form-group
              v-for="(item, index) in customFields"
              :key="item.id"
              class="col-md-6"
            >
              <!-- #default="{ errors }" -->
              <validation-provider
                :name="item.name"
                rules=""
                #default="validationContext"
              >
                <b-form-group v-if="item.type == 'number'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="data_local.get_customfields_data['field_' + item.id]"
                    :placeholder="item.label"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'text'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="data_local.get_customfields_data['field_' + item.id]"
                    :placeholder="item.label"
                    type="text"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'url'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="data_local.get_customfields_data['field_' + item.id]"
                    :placeholder="item.label"
                    type="url"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'rating'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <div class="d-flex align-items-center">
                    <star-rating
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :show-rating="false"
                      :star-size="30"
                    ></star-rating>
                    <span
                      v-if="data_local.get_customfields_data['field_' + item.id]"
                      :class="`rating-btn btn ${
                        ratingdescription[
                          data_local.get_customfields_data['field_' + item.id]
                        ].class
                      }`"
                    >
                      {{
                        ratingdescription[
                          data_local.get_customfields_data["field_" + item.id]
                        ].text
                      }}</span
                    >
                    <input
                      type="hidden"
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                    />
                  </div>
                </b-form-group>

                <b-form-group v-if="item.type == 'password'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="data_local.get_customfields_data['field_' + item.id]"
                    :placeholder="item.label"
                    type="password"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'textarea'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-textarea
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="data_local.get_customfields_data['field_' + item.id]"
                    trim
                    :placeholder="item.label"
                  />
                </b-form-group>
                <b-form-group v-if="item.type == 'date'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <flat-pickr
                    :config="{ dateFormat: 'Y-m-d' }"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="data_local.get_customfields_data['field_' + item.id]"
                    class="form-control"
                    placeholder="Select Date.."
                  />
                </b-form-group>

                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
        </b-form>
        <b-overlay :show="isBusy" no-wrap> </b-overlay>
      <!-- Comment Section -->
        <h4 class="font-weight-bolder my-1">Comments</h4>
        <div class="post-card common-cmd">
          <b-card class="shadow-none mb-0">
            <b-list-group>
              <!-- <b-list-group-item>
                <div class="goal-owner d-flex align-items-center pr-2 border-right">
                  <b-img-lazy fluid :src="require('@/assets/images/avatars/1.png')" alt="goal-img" width="42px" height="42px" fluid/>
                  <div class="pl-1">
                  <h6><b>You</b> updated the goal status to Delayed.</h6>
                  <span>2 days ago</span>
                  </div>
                </div>
              </b-list-group-item> -->
              <b-list-group-item
                v-for="comment in data_local.comments"
                class="bg-transparent reply-post py-50 border-0 px-0"
              >
                <div class="inner-cmd">
                  <div class="d-flex align-items-start ">
                    <b-img-lazy
                      fluid
                      :src="comment.user.avatar"
                      alt="user-img"
                      width="32px"
                      height="32px"
                      class="mr-75"
                    />
                    <div class="profile-user-info w-100 right-cmt">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="goal-owner d-flex align-items-center">
                          <div>
                            <h6 class="mb-0">
                              <b>{{ comment.user.name }}</b>
                            </h6>
                            <!-- <small class="mb-0 text-secondary">{{
                              comment.user.designation
                            }}</small> -->
                          </div>
                        </div>
                        <div class="d-flex align-items-start mb-1">
                          <span class="small mr-50">{{ comment.created_at }}</span>
                          <b-link class="btn btn-icon text-primary rounded-circle p-0 d-none d-lg-block"
                            ><feather-icon
                              icon="CornerUpLeftIcon"
                              size="18"
                              @click="addReply(comment)"
                          /></b-link>
                          <b-dropdown
                            class="blog-dropdown"
                            variant="link"
                            size="sm"
                            toggle-class="text-decoration-none p-0"
                            style="margin-top: 2px"
                            no-caret
                          >
                            <template v-slot:button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle text-muted"
                                v-b-tooltip.hover.v-default
                                title="More Options"
                              />
                            </template>
                            <b-dropdown-item
                              class="btn-sm p-0"
                              @click="addReply(comment)"
                            >
                              <feather-icon
                                icon="CornerUpLeftIcon"
                                size="13"
                                class="mr-50 text-primary"
                              />
                              <span class="">Reply to comment</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              class="btn-sm p-0"
                              @click="editReply(comment)"
                            >
                              <feather-icon
                                icon="EditIcon"
                                size="13"
                                class="mr-50 text-primary"
                              />
                              <span class="">Edit comment</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              class="btn-sm p-0"
                              @click="deleteConfirm(comment, data_local)"
                            >
                              <feather-icon
                                icon="XIcon"
                                size="13"
                                class="mr-50 text-danger"
                              />
                              <span class="">Delete comment</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              class="btn-sm p-0"
                              v-if="comment.visiblity_type == 'Private'"
                              @click="changeStatus(comment, 'Public')"
                            >
                              <feather-icon
                                icon="BookOpenIcon"
                                size="13"
                                class="mr-50 text-primary"
                              />
                              <span class="">Make it Public</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                      <p
                        v-html="comment.comment"
                        class="mb-0 mt-1"
                        style="letter-spacing: 1px; font-size: 14px"
                      ></p>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div class="border-top w-100 p-75 mt-50">

                  </div>
                </div>
                <div class="reply-cmd">
                  <Replies
                          v-if="comment.reply_count"
                          :itemId="comment.hashid"
                          :modelId="data_local.hashid"
                          :reply_count="comment.reply_count"
                          :mentionList="employeeListOptions"
                          v-on:refresh="refresh"
                        />
                  <b-collapse :id="'add-cmd-' + comment.hashid" class="my-50">
                    <div class="d-block my-50">
                    <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
                      <div class="border p-751 d-flex mb-0 flex-row align-items-center justify-content-around commentBox round w-100">
                        <CommentBox
                          placeholder="Reply to comment..."
                          className="w-100 comment-box"
                          :toolbar="false"
                          :mentionList="employeeListOptions"
                          :id="'reply-comment-field-' + comment.hashid"
                        />
                      </div>
                      <b-button
                        variant="primary"
                        @click="AddOKRComment(comment, data_local)"
                        class="blog-btn-block search-post rounded-circle p-75 ml-75 mb-0"
                        :disabled="commentSubmitClicked"
                      >
                        <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
                      </b-button>
                    </div>
                    </div>
                  </b-collapse>
                  <b-collapse :id="'edit-cmd-' + comment.hashid" class="my-50">
                    <div class="d-block mt-25">
                    <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
                    <div
                      class="border p-751 d-flex mb-0 flex-row align-items-center justify-content-around commentBox round w-100"
                    >
                      <CommentBox
                        placeholder="Reply to comment..."
                        className="w-100 comment-box"
                        :toolbar="false"
                        :mentionList="employeeListOptions"
                        :id="'edit-comment-field-' + comment.hashid"
                        :value.sync="comment.comment"
                      />
                      </div>
                      <b-button
                    variant="primary"
                    @click="editComment(comment, data_local.hashid)"
                    pill
                    class="blog-btn-block ml-50 search-post save-btn w-auto mb-0"
                    :disabled="commentSubmitClicked"
                  >
                  <strong><b>Save</b></strong>
                  </b-button>
                  </div>
                    </div>
                  </b-collapse>
                </div>
              </b-list-group-item>
              <b-list-group-item v-if="data_local.comments.length == 0">
                No Comments to Display.....
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card>
            <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
            <div class="border p-751 d-flex mb-0 flex-row align-items-center justify-content-around commentBox round w-100">
              <CommentBox
                placeholder="Add a comment..."
                className="w-100 comment-box "
                :toolbar="false"
                :mentionList="employeeListOptions"
                :id="'add-comment-field-' + data_local.hashid"
              />
              </div>
              <b-button
                  variant="primary"
                  @click="AddOKRComment(null, data_local)"
                  pill
                  class="blog-btn-block ml-75 search-post rounded-circle p-75 mb-0"
                  :disabled="commentSubmitClicked"
                >
                <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
                </b-button>
            </div>
          </b-card>
        </div>
        <!-- End Comment Section -->
       </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
  BInputGroup,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCollapse,
  BProgress,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import $ from "jquery";
import CommentBox from "@/components/CommentBox.vue";
import Replies from "@/views/tickets/list/Replies.vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    CommentBox,
    BInputGroup,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BProgress,
    Replies,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarActive",
    event: "update:sidebarActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      data_local: {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
        comments: [],
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      visibleComments: [],
      employeeListOptions: [],
      commentSubmitClicked:false,
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarActive(val) {
      this.data_local = {
        priority: "medium",
        status: "open",
        get_customfields_data: [],
        files: [],
        comments: [],
      };
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.ticket_hash_id) {
      this.fetchItem(this.$route.query.ticket_hash_id);
      console.log(this.$route.query.ticket_hash_id);
      this.$root.$emit('bv::toggle::collapse', 'ticketViewSidebar')
      this.$emit('update:sidebarActive', true)
    }
    this.getCustomFields();
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "tickets/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          this.fetchItem(this.data_local.hashid);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deleted",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: "Attachment Deleted Successfully.",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Attachment Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

        });

    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("ticket-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      this.isBusy = true;
      this.$store
        .dispatch("app-tickets/addTicket", formData)
        .then((res) => {
          // Add this ticket to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
            comments: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Ticket Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Ticket Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Ticket Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("ticket-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "tickets/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this ticket to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
            comments: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Ticket Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Ticket Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Ticket Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-tickets/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
          this.data_local.category_id = res.data.data.support_category.hashid;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-tickets/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.ticketStatusOption = res.data.meta.status;
          self.support_categories = res.data.support_categories;
          self.priority_option = res.data.priority_select;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    addReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      self.reply_to = comment.user.id;
      $("#add-cmd-" + comment.hashid).show();
      $("#edit-cmd-" + comment.hashid).hide();
    },
    editReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      $("#add-cmd").hide();
      $("#edit-cmd-" + comment.hashid).show();
      document.getElementById("edit-comment-field-" + comment.hashid).focus();
    },
    deleteConfirm(comment, okr) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this comment`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteComment(comment.hashid, okr.hashid);
        }
      });
    },

    deleteComment(commentId, okrId) {
      this.$http
        .delete(`/tickets/${okrId}/comments/${commentId}`)
        .then((res) => {
          this.visibleComments = [];
          this.data_local = res.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment deleted successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"successful-info",
              hideProgressBar : true,
            }); 
        });
    },

    changeStatus(comment, status) {
      let params = {};
      params = {
        comment: comment.comment,
        visiblity_type: status,
      };

      this.$http
        .patch(`tickets/comment/${comment.hashid}`, params)
        .then((res) => {
          this.visibleComments = [];
          for (
            let index = 0;
            index < this.data_local.comments.length;
            index++
          ) {
            if (this.data_local.comments[index].hashid == comment.hashid) {
              this.data_local.comments[index].visiblity_type = status;
            }
          }
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment Update Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Update Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    editComment(comment, okr_id) {
      this.commentSubmitClicked=true;
      const self = this;
      const message = document.getElementById(
        `edit-comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Reply cannot be empty",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Reply cannot be empty',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter the comments",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked=false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`tickets/comment/${comment.hashid}`, params)
        .then((res) => {
          // this.editComments.pop();
          this.visibleComments = [];
          this.data_local = res.data.data;
          this.comment_id = null;
          document.getElementById(
            `edit-comment-field-${comment.hashid}`
          ).value = "";
          $(".ql-editor").html("");
          $("#add-cmd").hide();
          $("#edit-cmd-" + comment.hashid).hide();
          this.refresh();
          this.commentSubmitClicked=false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked=false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    refresh() {
      this.$router.go();
    },
    AddOKRComment(cmd, okr) {
      this.commentSubmitClicked=true;
      if (cmd) {
        var comment = document.getElementById(
          `reply-comment-field-${cmd.hashid}`
        ).value;
      } else {
        var comment = document.getElementById(
          `add-comment-field-${okr.hashid}`
        ).value;
      }
      let params = {};
      params = {
        comment: comment,
        parent_comment_id: this.comment_id,
      };
      this.$http
        .post(`/tickets/${okr.hashid}/comments`, params)
        .then((res) => {
          this.visibleComments = [];
          this.data_local.comments = res.data.data.comments;
          this.comment_id = null;
          if (cmd) {
            document.getElementById(`reply-comment-field-${cmd.hashid}`).value =
              "";
          } else {
            document.getElementById(`add-comment-field-${okr.hashid}`).value =
              "";
          }
          $(".ql-editor").html("");
          $("#add-cmd").hide();this.commentSubmitClicked=false;
        })
        .catch((error) => {
          // Do something on error
          this.commentSubmitClicked=false;
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Ticket Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Ticket Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
